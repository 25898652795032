import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { jaJP as jaJPDatePicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import MemberSiteCollectionDetail, {
  IMemberToken,
  ITokensQuery,
  MemberNFT,
  nftStatus,
} from './MemberSiteCollectionDetail';

import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import MintedNFTDialog from '~/components/dialog/minted-nft-dialog';
import HomeBtn from '~/components/home-btn';
import LinkButton from '~/components/link-button';
import { IHandleListTable } from '~/components/list-table';
import LoaderCenter from '~/components/loader-center';
import { ITEMS_PER_PAGE } from '~/constants/common';
import { useConfirmationDialogContext } from '~/contexts/ConfirmationDialogProvider';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { env } from '~/env';
import {
  Collection,
  GetMyShopDocument,
  useGetMyShopCollectionByCollectionIdQuery,
  useUnAttachMyShopCollectionsMutation,
  useUpdateMemberTokenMutation,
  useListTokensQuery,
  ListTokensSortBy,
  ListTokensDocument,
  ListTokensQuery,
  Pagination,
} from '~/graphql/member/types';
import useMenu from '~/hooks/useMenu';
import { MEMBER_SITE_NFT_STATUS, MemberSiteDetailTab } from '~/types/my-shop';
import { getLocalStorage, setLocalStorageItems, verifyOrderKey, verifySortKey } from '~/utils/common';
import { getExpireDate } from '~/utils/getExpireDate';
import { ERROR_CODE, notFound } from '~/utils/not-found';

export interface NFTStatus {
  [key: string]: MEMBER_SITE_NFT_STATUS;
}

const useStyles = makeStyles()(({ breakpoints }) => ({
  dateFilter: {
    gap: '16px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'initial',
    },
    '.wrapperDatePickers': {
      gap: '16px',
      display: 'flex',
      [breakpoints.down('md')]: {
        width: '100%',
        '.MuiTextField-root': {
          flex: 1,
        },
      },
    },
  },
}));

const convertToDayjs = (date: Date | null) => (!!date ? dayjs(date) : null);

const WrapperMemberSiteCollectionDetail = () => {
  const { data: memberSite } = useShopDetail();

  const navigate = useNavigate();
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog } = useConfirmationDialogContext();

  const { id: shopId, collectionId } = useParams();

  const [shopName, setShopName] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<IMemberToken>();
  const [collectionInfo, setCollectionInfo] = useState<Collection>();
  const [isOpenNFTDialog, setIsOpenNFTDialog] = useState<boolean>(false);
  const [loadingTokensList, setLoadingTokensList] = useState<boolean>(true);
  const [loadingCollectionInfo, setLoadingCollectionInfo] = useState<boolean>(true);

  const initQuery = useMemo(
    () => ({
      page: 1,
      limit: ITEMS_PER_PAGE.LIST,
      searchText: '',
      sortBy: verifySortKey(
        ListTokensSortBy,
        getLocalStorage('membersite_collection_detail_sort'),
        ListTokensSortBy.MintTime
      ),
      orderBy: verifyOrderKey(getLocalStorage('membersite_collection_detail_order')),
      where: {
        mintTimeEnd: null,
        mintTimeStart: null,
        shopUuid: shopId || '',
        collectionUuid: collectionId || '',
      },
    }),
    [shopId, collectionId]
  );

  const [tokensQuery, setTokensQuery] = useState<ITokensQuery>(initQuery);

  const updateTokensQuery = (newValue: IHandleListTable) => setTokensQuery((value: any) => ({ ...value, ...newValue }));

  const onOpenInvalidDialog = () => {
    openDialog(
      t('member_site.invalid_collection'),
      t('member_site.invalidate_nft', { value: selectedItem?.metadataContent?.name }),
      {
        confirmTitle: t('member_site.invalid'),
        onConfirm: onInvalidateNFT,
      }
    );
  };

  const handleQuickChangeStatus = () => {
    if (selectedItem?.status === MEMBER_SITE_NFT_STATUS.INVALID) {
      onValidateNFT();
    } else {
      onOpenInvalidDialog();
    }
  };

  // Custom Hooks
  const { MenuComponent, onOpenMenu } = useMenu({
    menus: [
      {
        label: t('my_shop.show_detail'),
        onClick: () => setIsOpenNFTDialog(true),
      },
      ...([MEMBER_SITE_NFT_STATUS.INVALID, MEMBER_SITE_NFT_STATUS.VALID].includes(selectedItem?.status!)
        ? [
            {
              label:
                selectedItem?.status === MEMBER_SITE_NFT_STATUS.INVALID
                  ? t('member_site.change_to_validity')
                  : t('member_site.change_to_invalid'),
              onClick: handleQuickChangeStatus,
            },
          ]
        : []),
    ],
  });

  const { data: listTokensRes, loading: loadingTokensQuery } = useListTokensQuery({
    variables: tokensQuery,
    fetchPolicy: 'cache-and-network',
    skip: !collectionInfo?.contractAddress,
  });

  const handleFormatRows = (tokensList: ListTokensQuery | undefined, isOriginalDate: boolean = true) => {
    let newRows: MemberNFT[] = [];
    let newNFTsList: IMemberToken[] = [];
    (tokensList?.listTokens?.items || []).forEach((token) => {
      const nftActivationSettings = memberSite?.nftActivationSettings;

      if (nftActivationSettings) {
        const { acquisitionInformation: _, ...expireDate } = nftActivationSettings;
        const member = token?.member;
        const memberToken = token?.memberToken;
        const mintTime = moment.unix(Number(token.mintTime)).toString();
        const newExpireDate = getExpireDate(expireDate, mintTime);
        const imageURL = token?.image
          ? isOriginalDate
            ? token.image
            : env.REACT_APP_IPFS_GATEWAY_URL + encodeURI(token.image.replace('ipfs://', ''))
          : '/images/gu-logo.svg';
        // Get the current date
        const currentDate = moment();
        const newStatus = !member
          ? MEMBER_SITE_NFT_STATUS.NOT_REGISTERED
          : !!member.email && !member.verifyEmail
          ? MEMBER_SITE_NFT_STATUS.NOT_CONFIRM
          : !memberToken
          ? MEMBER_SITE_NFT_STATUS.UNRESTRICTED
          : currentDate.isSameOrAfter(newExpireDate)
          ? MEMBER_SITE_NFT_STATUS.EXPIRED
          : (memberToken.status as MEMBER_SITE_NFT_STATUS) || MEMBER_SITE_NFT_STATUS.VALID;

        newNFTsList.push({
          description: '',
          uuid: token.id,
          name: token.name,
          status: newStatus,
          tokenId: token.tokenId,
          memberUuid: member?.uuid,
          metadataUrl: token?.tokenURI ?? '',
          collectionUuid: collectionId || '',
          ownerAddress: token.ownerAddress || '',
          memberTokenUuid: memberToken?.uuid ?? '',
          createdAt: moment.unix(Number(token?.mintTime)).toString(),
          metadataContent: {
            image: imageURL,
            name: token.name,
            animation_url: '',
            description: token.description,
          },
        });
        const firstName = member?.firstName || '';
        const lastName = member?.lastName || '';
        const createdAtMoment = memberToken?.createdAt ? moment(memberToken?.createdAt) : undefined;
        const purchasedAt = createdAtMoment
          ? isOriginalDate
            ? createdAtMoment.toDate()
            : createdAtMoment.format(t('date_time_format'))
          : '';
        const mintTimeMoment = moment.unix(Number(token.mintTime));
        newRows.push({
          purchasedAt,
          id: token.id,
          url: imageURL,
          name: token.name,
          status: newStatus,
          memberUuid: member?.uuid,
          email: member?.email || '',
          verifyEmail: !!member?.verifyEmail,
          [ListTokensSortBy.TokenId]: token?.tokenId,
          [ListTokensSortBy.Owner]: token?.ownerAddress,
          ownerName: (firstName + ' ' + lastName).trim(),
          [ListTokensSortBy.MintTime]: isOriginalDate
            ? mintTimeMoment.toDate()
            : mintTimeMoment.format(t('date_time_format')),
          address: ((member?.address?.postalCode || '') + ' ' + (member?.address?.address || '')).trim(),
          expireDate: !newExpireDate
            ? ''
            : typeof newExpireDate === 'number'
            ? 'Infinity'
            : isOriginalDate
            ? newExpireDate
            : moment(newExpireDate).format(t('date_time_format')),
        });
      }
    });
    return { newRows, newNFTsList, pagination: listTokensRes?.listTokens.pagination ?? ({} as Pagination) };
  };

  const { rows, nftsList, pagination } = useMemo(() => {
    setLoadingTokensList(true);
    const { newRows, newNFTsList, pagination } = handleFormatRows(listTokensRes);
    setLoadingTokensList(false);
    return { rows: newRows, nftsList: newNFTsList, pagination };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listTokensRes, collectionId, memberSite?.nftActivationSettings]);

  const isLoadingTable = loadingTokensQuery || loadingTokensList;

  useGetMyShopCollectionByCollectionIdQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        collectionUuid: collectionId!,
        shopUuid: shopId!,
      },
    },
    onCompleted: (data) => {
      const myShopCollection = data.getMyShopCollectionByCollectionId;
      const collectionInfoRes = myShopCollection.collection!;
      setCollectionInfo(collectionInfoRes);
      setTokensQuery(initQuery);
      setLoadingCollectionInfo(false);
    },
    onError: (err) => {
      setLoadingCollectionInfo(false);
      notFound(Object.values(err)?.[0]?.[0]?.extensions?.code, navigate);
    },
  });

  const [updateMemberToken] = useUpdateMemberTokenMutation({
    refetchQueries: [ListTokensDocument],
  });
  const [unattachCollectionShop] = useUnAttachMyShopCollectionsMutation({
    refetchQueries: [GetMyShopDocument],
  });

  // Handle Member Tokens status
  const onInvalidateNFT = async () => {
    setIsOpenNFTDialog(false);
    try {
      if (selectedItem?.memberTokenUuid) {
        await updateMemberToken({
          variables: {
            input: {
              uuid: selectedItem?.memberTokenUuid || '',
              status: MEMBER_SITE_NFT_STATUS.INVALID,
            },
          },
        });
        enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
        setLoadingTokensList(true);
      }
    } catch (err: any) {
      enqueueSnackbar(
        err?.graphQLErrors?.[0]?.extensions?.code === ERROR_CODE.NOT_FOUND
          ? t('toast_message.member_token_not_supported')
          : err.message || t('my_shop.message.error'),
        { variant: 'error' }
      );
    }
  };

  // Handle Menu
  const handleOpenMenu = useCallback(
    (row: MemberNFT): MouseEventHandler<HTMLButtonElement> =>
      (e) => {
        setSelectedItem(nftsList.find((nft) => nft.uuid === row.id));
        onOpenMenu(e);
      },
    [nftsList, onOpenMenu]
  );

  const onOpenNFTDialog = () => {
    setIsOpenNFTDialog(true);
  };
  const onCloseNFTDialog = () => {
    setIsOpenNFTDialog(false);
  };

  const onValidateNFT = async () => {
    onCloseNFTDialog();
    try {
      if (selectedItem?.memberTokenUuid) {
        await updateMemberToken({
          variables: {
            input: {
              uuid: selectedItem?.memberTokenUuid || '',
              status: MEMBER_SITE_NFT_STATUS.VALID,
            },
          },
        });
        enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
        setLoadingTokensList(true);
      }
    } catch (err: any) {
      enqueueSnackbar(err.err.message || t('my_shop.message.error'), { variant: 'error' });
    }
  };

  const onUnattachCollection = useCallback(async () => {
    try {
      await unattachCollectionShop({
        variables: {
          input: {
            shopUuid: shopId || '',
            collectionUuids: [collectionId || ''],
          },
        },
      });
      navigate(AppRouteEnum.MemberSiteDetail.replace(/:id/g, shopId || ''));
    } catch (e: any) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId, collectionId, collectionInfo, t, navigate, enqueueSnackbar, unattachCollectionShop]);

  useEffect(() => {
    setLocalStorageItems({
      membersite_collection_detail_sort: tokensQuery?.sortBy,
      membersite_collection_detail_order: tokensQuery?.orderBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokensQuery?.orderBy, tokensQuery?.sortBy]);

  useEffect(() => {
    if (memberSite?.siteSetting?.title) {
      setShopName(memberSite?.siteSetting?.title ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberSite?.siteSetting?.title]);

  const menus = [
    {
      title: t('member_site.remove_from_membersite'),
      onClick: () => {
        openDialog(t('member_site.remove_from_membersite'), t('member_site.remove_confirm_from_membersite'), {
          confirmTitle: t('remove'),
          onConfirm: onUnattachCollection,
        });
      },
    },
  ];

  const renderNFTDialogAction = () => {
    const isNotReady =
      selectedItem?.status &&
      ![MEMBER_SITE_NFT_STATUS.INVALID, MEMBER_SITE_NFT_STATUS.VALID].includes(selectedItem?.status);
    const isInvalid = selectedItem?.status && selectedItem?.status === MEMBER_SITE_NFT_STATUS.INVALID;
    return (
      <Button
        key="action"
        variant="outlined"
        disabled={isNotReady}
        color={isInvalid ? 'primary' : 'error'}
        onClick={isInvalid ? onValidateNFT : onOpenInvalidDialog}
        sx={{
          float: 'right',
        }}
      >
        {isNotReady
          ? nftStatus(t)[selectedItem.status].title
          : isInvalid
          ? t('member_site.validity')
          : t('member_site.invalid')}
      </Button>
    );
  };

  const handleChangeFilterDate =
    (isStart = false) =>
    (newDate: Dayjs | null) => {
      const start = !!newDate ? newDate.format() : null;
      const end = !!newDate ? newDate.set('h', 23).set('m', 59).set('s', 59).format() : null;
      const where = isStart ? { mintTimeStart: start } : { mintTimeEnd: end };
      setTokensQuery((value: any) => ({ ...value, page: 1, where: { ...value.where, ...where } }));
    };

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Link
          component={RouterLink}
          to={AppRouteEnum.MemberSite}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {t('member_site.member_site')}
        </Link>
        <Link
          component={RouterLink}
          to={AppRouteEnum.MemberSiteDetail.replace(/:id/g, shopId || '')}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          style={{ wordBreak: 'break-all' }}
        >
          {shopName}
        </Link>
        <Typography
          style={{ wordBreak: 'break-all' }}
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.secondary"
        >
          {collectionInfo?.name || '...'}
        </Typography>
      </Breadcrumbs>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18n.language}
        localeText={jaJPDatePicker.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <Box className={classes.dateFilter}>
          <LinkButton
            startIcon={<ArrowBackIcon />}
            to={`${AppRouteEnum.MemberSiteDetail.replace(/:id/g, shopId || '')}?tab=${MemberSiteDetailTab.COLLECTION}`}
          >
            {t('back')}
          </LinkButton>
          <Box className="wrapperDatePickers">
            <DatePicker
              label={t('start')}
              slotProps={{ field: { clearable: true } }}
              value={convertToDayjs(tokensQuery.where.mintTimeStart)}
              maxDate={convertToDayjs(tokensQuery.where.mintTimeEnd) || dayjs()}
              onChange={handleChangeFilterDate(true)}
            />
            <DatePicker
              label={t('end')}
              slotProps={{ field: { clearable: true } }}
              value={convertToDayjs(tokensQuery.where.mintTimeEnd)}
              maxDate={dayjs().set('h', 23).set('m', 59).set('s', 59)}
              onChange={handleChangeFilterDate()}
            />
          </Box>
        </Box>
      </LocalizationProvider>
      <MenuComponent />
      <Box width="100%" height="100%">
        {loadingCollectionInfo ? (
          <LoaderCenter />
        ) : (
          <CustomCardTable
            cardTitle={collectionInfo?.name || '...'}
            headerAction={<HeaderAction menus={menus} />}
            cardContent={
              <MemberSiteCollectionDetail
                rows={rows}
                nftsList={nftsList}
                pagination={pagination}
                tokensQuery={tokensQuery}
                isLoading={isLoadingTable}
                collectionInfo={collectionInfo}
                formatRows={handleFormatRows}
                handleOpenMenu={handleOpenMenu}
                setSelectedItem={setSelectedItem}
                onOpenNFTDialog={onOpenNFTDialog}
                updateTokensQuery={updateTokensQuery}
              />
            }
          />
        )}
      </Box>
      <MintedNFTDialog
        open={isOpenNFTDialog}
        nftInfo={selectedItem!}
        collectionInfo={collectionInfo!}
        onClose={onCloseNFTDialog}
        actions={[renderNFTDialogAction()]}
      />
    </>
  );
};

export default WrapperMemberSiteCollectionDetail;
